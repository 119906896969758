<script setup lang="ts">
import { SDivider, SElevationTokens, SSheet } from 'sima-suite-components-vue3'
import { useHost } from '@/composables'
import { useTranslator } from 'sima-suite-translator-vue3'
import { computed, onMounted, ref } from 'vue'
import { useAppointmentStore } from '@/store'
import SWizard from '@/components/organims/SWizard/SWizard.vue'
import WorkshopStep from '@/containers/WorkshopStep/WorkshopStep.vue'
import OcsBrandLogo from '@/components/atoms/OcsBrandLogo/OcsBrandLogo.vue'
import ButtonPanel from '@/components/organims/ButtonPanel/ButtonPanel.vue'
import VehicleStep from '@/containers/VehicleStep/VehicleStep.vue'
import ServicesStep from '@/containers/ServicesStep/ServicesStep.vue'
import AppointmentStep from '@/containers/AppointmentStep/AppointmentStep.vue'
import AdviserAvailabilityStep from '@/containers/AdviserAvailabilityStep/AdviserAvailabilityStep.vue'
import ContactDetailsStep from '@/containers/ContactDetailsStep/ContactDetailsStep.vue'
import ConfirmAppointmentStep from '@/containers/ConfirmAppointmentStep/ConfirmAppointmentStep.vue'
import { changeLocationHash } from '@/utils'
import { useLocale } from 'vuetify'
import { useRouter } from 'vue-router'
import { ROUTE_APPOINTMENT_CONFIRM, ROUTE_APPOINTMENT_FAILURE } from '@/router/routeNames.ts'
import RedirectToCorrectBrandStep from '@/containers/RedirectToCorrectBrandStep/RedirectToCorrectBrandStep.vue'
//@ts-ignore
import GoogleTagManagerWrapper from '@/utils/googleTagManagerWrapper.js'
//@ts-ignore
import HotjarWrapper from '@/utils/hotjarWrapper.js'

const { brandLogo, brandColor, brandsWeb } = useHost()

const appointmentStore = useAppointmentStore()

const isLoading = ref(false)

const logo = computed(() => {
  if (brandLogo.value) {
    return new URL(`../../assets/themes/logos/${brandLogo.value}`, import.meta.url).href
  }
  return ''
})

const { translate, language } = useTranslator()
const { current: currentLocale } = useLocale()

const googleTagManager = new GoogleTagManagerWrapper()
const hotjar = new HotjarWrapper()

currentLocale.value = language.value

const wizardTabs = ref<string[]>([
  translate('AppointmentWizard.Steps.Step1'),
  translate('AppointmentWizard.Steps.Step2'),
  translate('AppointmentWizard.Steps.Step3'),
  translate('AppointmentWizard.Steps.Step4'),
  translate('AppointmentWizard.Steps.Step5'),
  translate('AppointmentWizard.Steps.Step6'),
  translate('AppointmentWizard.Steps.Step7')
])

const step = ref<number>(0)
const steps = [
  WorkshopStep,
  VehicleStep,
  ServicesStep,
  AppointmentStep,
  AdviserAvailabilityStep,
  ContactDetailsStep,
  ConfirmAppointmentStep,
  RedirectToCorrectBrandStep
]

const stepsName = [
  'Workshops',
  'VehicleForm',
  'WorkshopServices',
  'AppointmentDate',
  'AdviserAvailability',
  'ContactForm',
  'SummaryAppointment',
  'Redirect'
]

onMounted(() => {
  changeLocationHash(stepsName[step.value])
  hotjar.stateChange(window.location.href)
})

const registerPageViewEvent = () => {
  switch (step.value) {
    case 0:
      googleTagManager.withWorkshop(appointmentStore.workshopSelected.name)
      googleTagManager.trackStep2()
      break
    case 1:
      googleTagManager.trackStep3()
      break
    case 2:
      googleTagManager.withServices(
        appointmentStore.selectedServices.map((item) => item.name).join(', ')
      )
      googleTagManager.withRemarks(
        appointmentStore.selectedServices
          .filter((item) => item.hasRemarks)
          .map((item) => item.remarks)
          .join(', ')
      )
      googleTagManager.trackStep4()
      break
    case 3:
      googleTagManager.trackStep5()
      break
    case 4:
      googleTagManager.trackStep6()
      break
    case 5:
      googleTagManager.trackStep7()
      break

    default:
      break
  }
}

const nextStep = async () => {
  if (appointmentStore.workshopSelected.redirectUrl) {
    googleTagManager.trackStep2Exit()
    window.location.href = appointmentStore.workshopSelected.redirectUrl
  }
  if (import.meta.env.MODE === 'production') {
    registerPageViewEvent()
  }
  if (step.value === 1) {
    try {
      isLoading.value = true
      await appointmentStore.getVehicle()
      const isAllowBrand = appointmentStore.workshopSelected.brands?.some(
        (brand) => brand.code === appointmentStore.vehicle!.brand!.code
      )
      const brandsMapping = Object.keys(brandsWeb.value)
      const hasBrandMapping = brandsMapping.includes(appointmentStore.vehicle!.brand!.code!)
      if (appointmentStore.vehicle.vin && !isAllowBrand && hasBrandMapping) {
        step.value = 7
        return
      }
    } catch (e) {
      step.value++
      changeLocationHash(stepsName[step.value])
      hotjar.stateChange(window.location.href)
      return
    } finally {
      isLoading.value = false
    }
  }

  step.value++
  changeLocationHash(stepsName[step.value])
  hotjar.stateChange(window.location.href)
}

const previousStep = () => {
  step.value--
  changeLocationHash(stepsName[step.value])
  hotjar.stateChange(window.location.href)
}

const router = useRouter()

const isServiceValid = computed(() => {
  const services = appointmentStore.selectedServices
  const someWithoutRemark = services.some((service) => service.checked && !service.hasRemarks)
  const someWithRemark = services.some(
    (service) =>
      service.checked &&
      service.hasRemarks &&
      service.remarks!.length > 4 &&
      service.remarks!.length <= 70
  )

  const everyWithoutRemark = services.every((service) => service.checked && !service.hasRemarks)
  const everyWithRemark = services.every(
    (service) =>
      service.checked &&
      service.hasRemarks &&
      service.remarks!.length > 4 &&
      service.remarks!.length <= 70
  )

  if (!services.length) {
    return false
  }

  if (someWithRemark && someWithoutRemark) {
    return true
  }

  if (everyWithRemark) {
    return true
  }
  return everyWithoutRemark
})

const handleWizardUpdateStep = (value: number) => {
  step.value = value
  changeLocationHash(stepsName[step.value])
  hotjar.stateChange(window.location.href)
}

const isNextDisabled = computed(() => {
  switch (step.value) {
    case 0:
      return isNaN(appointmentStore.workshopSelected.id)
    case 1:
      return !appointmentStore.isVehicleFormValid
    case 2:
      return !isServiceValid.value
    case 3: {
      const hasDateSelected = appointmentStore.workshopAvailabilityDate
      const hasHourSelected = Number.isNaN(appointmentStore.workshopAvailabilityRange)
      return !(hasDateSelected && !hasHourSelected)
    }
    case 4:
      return !appointmentStore.workshopAvailabilityAdviser
    case 5:
      return !appointmentStore.isContactFormValid
  }

  return false
})

const isFirstStep = computed(() => {
  return step.value === 0
})

const isLastStep = computed(() => {
  return step.value === steps.length - 2
})

const confirmAppointment = async () => {
  isLoading.value = true
  try {
    await appointmentStore.createAppointment()
    googleTagManager.trackStep8()
    router.push({
      name: ROUTE_APPOINTMENT_CONFIRM,
      state: {
        title: translate('Success.CreateAppointment.Title'),
        description: translate('Success.CreateAppointment.Description')
      }
    })
  } catch (e) {
    router.push({
      name: ROUTE_APPOINTMENT_FAILURE,
      state: {
        title: translate('Error.CreateAppointment.Title'),
        description: translate('Error.CreateAppointment.Description')
      }
    })
  } finally {
    isLoading.value = false
  }
}
</script>

<template>
  <div>
    <s-sheet
      :elevation="SElevationTokens.null"
      class="w-full bg-color-white md:ml-auto md:mr-auto md:mt-spacing-xxxl md:h-fit md:w-3/4 md:shadow-shadow-m lg:w-3/5 lg:max-w-[1200px]"
    >
      <div
        class="flex flex-col items-center gap-spacing-xs p-spacing-s sm:ml-spacing-s sm:justify-center md:justify-evenly"
      >
        <ocs-brand-logo :src="logo" :alt="translate('AppointmentWizard.LogoAlt')" />
        <div class="w-full">
          <s-wizard
            :color="brandColor"
            :step="step"
            :steps="wizardTabs"
            @update:step="handleWizardUpdateStep"
          />
        </div>
      </div>
      <s-divider />
      <div
        class="h-[calc(100dvh_-_17rem)] px-spacing-m pt-spacing-s sm:h-[calc(100dvh_-_18rem)] md:h-[calc(100dvh_-_23rem)]"
      >
        <keep-alive>
          <component :is="steps[step]" />
        </keep-alive>
      </div>
      <div
        v-if="step !== 7"
        class="fixed bottom-0 h-fit w-full rounded-b-2xl bg-color-white shadow-[0_-4px_4px_0_rgba(173,173,173,0.1)] sm:relative sm:shadow-none"
      >
        <button-panel
          :is-next-disabled="isNextDisabled"
          :is-first-step="isFirstStep"
          :is-last-step="isLastStep"
          :is-confirm-loading="isLoading"
          @click:next="nextStep"
          @click:back="previousStep"
          @click:confirm="confirmAppointment"
        />
      </div>
    </s-sheet>
  </div>
</template>
